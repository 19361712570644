@import 'styles/flex-mixins';
@import 'styles/media';
@import 'styles/generics';

.user-info_wrapper {
    position: relative;

    flex: 1;

    max-width: 270px;
    height: var(--header-height);

    color: var(--neutral-00);

    .user-info {
        @include row(flex-end, center, 10px);
        @include landscape-max-width {
            min-width: fit-content;
        }
        @include tablet-max-width {
            padding: 0 5px;
        }

        cursor: pointer;
        user-select: none;

        width: 100%;
        height: 100%;
        padding: 8px;

        text-align: center;

        &--active {
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            box-shadow: 0 2px 7px #00000012;
        }

        .avatar {
            width: 54px;
            height: 54px;

            border-radius: 15px;
        }

        .name {
            @include landscape-max-width {
                display: none;
            }
            overflow: hidden;
        }

        .arrow {
            width: 15px;
            height: 15px;
        }

        .arrow--rotated {
            transform: rotate(180deg);
        }
    }

    .dropdown_menu {
        width: 100%;
        min-width: 250px;
        padding: 12px 0 20px;

        border-top: 2px solid var(--primary-01-light);
        border-radius: 20px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        box-shadow: 0 2px 7px #00000012;;

        &__item {
            @include row(flex-start, center, 0);

            padding-right: 20px;
            padding-left: 20px;

            text-align: start;

            &.balance {
                margin-bottom: 5px;
            }
        }
    }

    .limiter_line {
        width: 100%;
        margin: 10px auto;

        color: var(--neutral-05);

        &::before,
        &::after {
            content: '';

            flex: 1 1;

            margin: auto;

            border-bottom: 1px solid var(--neutral-05);
        }
    }
}
