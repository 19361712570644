@import 'styles/flex-mixins';

.transcription_details_form {
    @include column(flex-start, flex-start, 4vmin);
    max-width: 500px;

    > .item {
        @include column(flex-start, flex-start, 20px);
        min-width: 350px;

        > span {
            margin-top: 5px;
            padding-left: 0.5rem;

            font-size: 14px;
            color: var(--neutral-04);
        }
    }

    .speakers_count_wrapper {
        @include row(flex-start, baseline, 1vmin);
    }

    .diarization_wrapper {
        @include row(flex-start, flex-start, 1.5vmin);

        .tooltip {
            position: relative;

            display: inline-block;

            .icon {
                display: flex;
                align-items: center;

                width: 20px;
                height: 20px;

                color: var(--neutral-02);
            }
        }

        .tooltip .tooltiptext {
            position: absolute;
            z-index: 1;
            left: 150%;
            transform: translateY(-60%);

            width: 300px;
            padding: 5px 10px;

            color: var(--primary-01-dark);
            text-align: center;

            visibility: hidden;
            background-color: var(--primary-01-extra-light);
            border-radius: 6px;
        }

        .tooltip:hover .tooltiptext {
            visibility: visible;
        }

        .tooltip .tooltiptext::after {
            content: ' ';

            position: absolute;
            top: 50%;
            right: 100%;

            margin-top: -5px;

            border-color: transparent var(--primary-01-extra-light) transparent transparent;
            border-style: solid;
            border-width: 5px;
        }
    }

    .language_select {
        width: 70%;

        div[class$='-control'] {
            height: 48px;

            border: 2px solid var(--primary-01-light);
            border-radius: var(--default-border-radius);
        }
    }

    .btn {
        width: 260px;
    }
}
