.control-menu {
    position: relative;

    > button {
        width: 38px;
        height: 38px !important;
        padding: 2px !important;

        border-radius: 4px !important;

        svg {
            width: 30px;
            height: 30px;
        }
    }

    &__delete {
        color: var(--service-01);
    }

    .dropdown_menu {
        top: calc(100% + 10px);
        left: 0;

        width: 210px;
        padding: 10px 14px;

        border-radius: 20px;
    }

    .dropdown_menu__item {
        padding: 10px 5px;

        svg {
            width: 24px;
            margin-right: 15px;
        }

        > span {
            overflow: hidden;

            width: 100%;

            font-size: 14px;
            text-align: left;
            text-overflow: ellipsis;
        }
    }

    // .dropdown_menu__item:not(:last-child) {
    //     padding-bottom: 10px;
    // }
}
