.transcript-name {
    overflow: hidden;

    width: fit-content;

    font-size: 18px;
    font-weight: 600;
    text-overflow: ellipsis;

    &--editable {
        cursor: pointer;
    }
}
