.transcript-control {
    width: 100%;

    background-color: white;

    &__top {
        display: flex;

        padding: 1vw 1vw 0 1.75vw;

        > svg {
            margin: 3px;
        }
    }

    &__logo {
        cursor: pointer;
    }

    &__close {
        cursor: pointer;

        margin: 3px;
    }

    &__content {
        flex: 1;

        margin: 0 calc(var(--side-margin) - 1vw - 30px) 0 calc(var(--side-margin) - 1.75vw - 44px);

        > div {
            display: flex;
            gap: 15px;
            align-items: center;
            justify-content: space-between;

            margin-bottom: 15px;
        }

        .player {
            flex: 1;
        }
    }

    &__name-wrapper {
        display: flex;
        gap: 25px;
        align-items: center;
    }

    &__readonly-label {
        padding: 5px 10px;

        font-size: 12px;
        color: var(--neutral-03);

        background-color: var(--neutral-08);
        border-radius: 4px;
    }

    &__upload-date,
    &__saving-date {
        padding: 0 35px;

        font-size: 12px;
        color: var(--neutral-03);
    }

    &__share {
        width: 100px;
        height: 36px !important;

        font-size: 14px !important;

        border-radius: 6px !important;
    }

    &__bottom {
        display: flex;
        align-items: center;

        height: 40px;
        padding: 0 1vw;

        background-color: var(--primary-01-light);
    }

    &__actions {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: space-between;

        margin: 0 calc(var(--side-margin) - 1vw - 24px) 0 calc(var(--side-margin) - 1vw - 12px);
    }

    &__protocol {
        justify-content: flex-end;

        height: 30px !important;

        font-weight: 700;
        color: #544080;

        border-radius: 4px !important;

        span {
            font-size: 14px;
        }
    }

    &__up {
        cursor: pointer;

        width: 24px;
        height: 24px;

        text-align: center;

        svg {
            transform: rotate(180deg);
        }
    }

    &__actions-group {
        display: flex;
        gap: 4px;
        align-items: center;
        justify-content: center;

        margin: 0;
        padding: 0 12px;

        border-right: 1px solid var(--neutral-05);

        .btn {
            width: 32px;
            height: 30px;
            padding: 4px;

            svg {
                fill: #544080;
            }

            &-disabled {
                svg {
                    fill: var(--neutral-04);
                }
            }
        }
    }
}
