.demo-viewer {
    width: 100%;

    .btn_with_icon {
        background: transparent;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-bottom: 5px;

        h2 {
            font-size: 20px;
        }

        button {
            color: var(--primary-01);

            span {
                font-size: 14px;
            }
        }
    }

    &__spinner {
        display: flex;
        justify-content: center;

        margin-top: 30px;
    }

    &__create-button {
        margin: auto;
        margin-top: 15px;

        font-size: 14px !important;
    }

    &__text {
        padding: 15px 24px;

        white-space: pre-line;

        background-color: white;
        border-radius: var(--default-border-radius);
    }
}

.projects-viewer {
    &__spinner {
        display: flex;
        justify-content: center;

        margin: 30px 0;
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        margin-top: 20px;
    }

    .back_arrow {
        cursor: pointer;

        margin: 0 0 24px;

        color: var(--neutral-02);

        &::before {
            content: url('/assets/arrow-without-body.svg');

            transform: rotate(90deg);

            display: inline-block;

            margin: 0 10px;

            filter: opacity(0.6);
        }
    }
}
